






































































import Vue from "vue";
import { DELIVERY_ORDER_QC_OPTIONS } from "@constant/delivery-order-qc.constant";
import { OptionsDeliveryOrderQC, RequestQualityControlDelivery } from "@interface/delivery-order.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { deliveryOrderService } from "@service/delivery-order.service";

export default Vue.extend({
  name: "DeliveryOrderModalQC",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    propDoId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      dtSource: [] as OptionsDeliveryOrderQC[],
      loading: false,
    };
  },
  computed: {
    disableSubmit(): boolean {
      return !!this.dtSource.find(x => !x.selected);
    }
  },
  created() {
    this.dtSource = DELIVERY_ORDER_QC_OPTIONS;
    this.dtSource.forEach(x => x.selected = undefined);
  },
  methods: {
    constructReq(data: {criteria: string, value: string | undefined }[]): RequestQualityControlDelivery {
      let req: RequestQualityControlDelivery = {
        qty: "",
        cuttingProcess: "",
        packagingCondition: "",
        productColor: "",
        productCondition: "",
      };
      data.forEach(x => {
        if (x.criteria === "qty_weight") req.qty = x.value || "";
        if (x.criteria === "products_colour") req.productColor = x.value || "";
        if (x.criteria === "packaging_condition") req.packagingCondition = x.value || "";
        if (x.criteria === "cutting_process") req.cuttingProcess = x.value || "";
        if (x.criteria === "product_condition") req.productCondition = x.value || "";
      });
      return req;
    },
    async handleSave(): Promise<void> {
      try {
        this.loading = true;
        const data = this.dtSource.map(x => ({criteria: x.criteria, value: x.selected}));
        if (this.propDoId) {
          const req = this.constructReq(data);
          await deliveryOrderService.deliveryOrderUpdateQC(this.propDoId, req);
          await deliveryOrderService.approveDO(this.propDoId);
          this.showSubmitSuccessMesssage();
          this.$emit("input", false);
          this.$emit("on-save", { data });
        }
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading = false;
      }
    },
    handleClose(): void {
      this.$emit("input", false);
    },
  }
});
