import { OptionsDeliveryOrderQC } from "../interface-v2/delivery-order.interface";

export const DELIVERY_ORDER_QC_OPTIONS: OptionsDeliveryOrderQC[] = [
  {
    key: 0,
    label: "lbl_qc_do_1",
    criteria: "qty_weight",
    options: [
      {
        label: "lbl_sesuai",
        value: "good",
      },
      {
        label: "lbl_tidak_sesuai",
        value: "not_good",
      },
    ],
    selected: undefined,
  },
  {
    key: 1,
    label: "lbl_qc_do_2",
    criteria: "products_colour",
    options: [
      {
        label: "lbl_baik",
        value: "good",
      },
      {
        label: "lbl_tidak_baik",
        value: "not_good",
      },
    ],
    selected: undefined,
  },
  {
    key: 2,
    label: "lbl_qc_do_3",
    criteria: "packaging_condition",
    options: [
      {
        label: "lbl_baik",
        value: "good",
      },
      {
        label: "lbl_rusak",
        value: "not_good",
      },
    ],
    selected: undefined,
  },
  {
    key: 3,
    label: "lbl_qc_do_4",
    criteria: "cutting_process",
    options: [
      {
        label: "lbl_sesuai",
        value: "good",
      },
      {
        label: "lbl_tidak_sesuai",
        value: "not_good",
      },
    ],
    selected: undefined,
  },
  {
    key: 4,
    label: "lbl_qc_do_5",
    criteria: "product_condition",
    options: [
      {
        label: "lbl_chilled_0_to_4",
        value: "chilled",
      },
      {
        label: "lbl_frozen_than_minus_5",
        value: "frozen",
      },
    ],
    selected: undefined,
  },
];
