var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    { attrs: { visible: _vm.value, width: 720, closable: false } },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("lbl_quality_control")))
      ]),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dtSource,
            pagination: false,
            scroll: { y: 520 }
          }
        },
        [
          _c(
            "a-table-column",
            {
              key: "label",
              attrs: { "data-index": "label", width: 200 },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t(record.label) || "-") + " ")
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_criteria")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "senderNotes",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c(
                        "a-radio-group",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: record.selected,
                            callback: function($$v) {
                              _vm.$set(record, "selected", $$v)
                            },
                            expression: "record.selected"
                          }
                        },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16], type: "flex" } },
                            _vm._l(record.options, function(opt) {
                              return _c(
                                "a-col",
                                { key: opt.value, attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-radio",
                                    { attrs: { value: opt.value } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(opt.label)) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_sender_notes")))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.handleClose } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
          ]),
          _c(
            "a-button",
            {
              key: "submit",
              attrs: {
                type: "primary",
                icon: "save",
                loading: _vm.loading,
                disabled: _vm.disableSubmit
              },
              on: { click: _vm.handleSave }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit_and_approve")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }